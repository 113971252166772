import { getProvider } from "@/utils/getProvider";
import { Link as RouterLink } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Alert, Card, Collapse, Link, Stack, TextField, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { blacklist } from "@/constants";
import {
    LoginOpts,
    LoginSuccessResponse,
    OryError,
    getAppropriateLoginMethod,
    oidcLogin,
    passwordLogin,
    totpLogin,
} from "@/hooks/login";
import { getRequireAal2 } from "@/hooks/useSession";
import { SSOProvider } from "@/types";
import { getCurrentDomain } from "@/utils/getCurrentHostname";
import { identify, track } from "@ignite-analytics/track";
import messages from "./messages";
import { IgniteIcon } from "./Icon";

interface Props {
    title: string;
    subtitle?: string;
    refresh?: boolean;
    redirectTo?: string;
    onShowPassword?: () => void;
    initialEmail?: string;
}

const LoginForm: React.FC<Props> = ({ initialEmail, refresh, redirectTo, title, subtitle, onShowPassword }) => {
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const mfaRef = useRef<HTMLInputElement>(null);
    const [email, setEmail] = useState("");
    const [requireMfa, setRequireMfa] = useState(false);
    const [ssoProvider, setSsoProvider] = useState<string | null>(null); // the required sso provider they must log in with
    const [error, setError] = useState("");

    useEffect(() => {
        if (initialEmail) {
            setEmail(initialEmail);
        }
    }, [initialEmail]);

    useEffect(() => {
        getRequireAal2().then((r) => setRequireMfa(r));
    }, []);

    useEffect(() => {
        const currentTarget = usernameRef?.current;
        if (currentTarget) {
            setTimeout(() => currentTarget.focus(), 200);
        }
    }, [usernameRef]);

    function humanReadableError(error: OryError["error"]): string {
        let msg: string;
        const descriptor = messages[error.code as keyof typeof messages];
        if (descriptor == undefined) {
            msg = error.details ?? formatMessage(messages.genericError);
        } else {
            msg = formatMessage(descriptor);
        }
        return msg;
    }

    async function ssoLogin(provider: SSOProvider, email: string, domain?: string, opts?: LoginOpts) {
        setLoading(true);
        const { data, error } = await oidcLogin(email, provider, domain, opts);
        if (error) {
            if (error.code == "session_already_available") {
                Sentry.captureMessage("session_already_available, redirect to app", {
                    user: { email },
                    tags: {
                        method: "password",
                        app: "auth-frontend",
                        emailDomain: email.split("@")[1],
                    },
                    level: "info",
                });
                redirectToApp();
                return;
            }
            setError(humanReadableError(error));
            Sentry.captureMessage("login failed", {
                tags: {
                    method: provider.name,
                    app: "auth-frontend",
                    emailDomain: email.split("@")[1],
                    code: error.code,
                    details: error.details,
                },
            });
            setLoading(false);
            return;
        }
        Sentry.captureMessage("oidc redirect", {
            user: { email },
            tags: {
                method: provider.name,
                emailDomain: email.split("@")[1],
                app: "auth-frontend",
            },
        });
        window.location.href = data.redirect;
        return;
    }
    function onLoginSuccess(r: LoginSuccessResponse["data"]) {
        const { method, tenant, userId, email } = r;
        if (blacklist.includes(userId)) {
            redirectToApp();
            return;
        }
        identify({ id: userId, email, tenantName: tenant ?? "", type: "authenticted" });
        Sentry.captureMessage("login success", {
            level: "info",
            tags: {
                method,
                app: "auth-frontend",
                emailDomain: email.split("@")[1],
                userId: r.userId,
                tenant: r.tenant,
            },
        });
        const loggedInWithOidc = (r.session.authentication_methods || []).some((am) => am.method == "oidc");
        if (ssoProvider !== null && !loggedInWithOidc) {
            // user logged in, but we require SSO to log into this workspace. User must
            // link, so we'll redirect them to a page where we help them set that up.
            track("login: must link account", { linkWith: ssoProvider, method });
            localStorage.setItem("ms-link-state", JSON.stringify({ enabled: false }));
            window.location.href = `/link-account?provider=${ssoProvider}`;
            return;
        }
        track("login success", { method: "password", email, userId, tenant });
        redirectToApp();
    }
    function redirectToApp() {
        if (redirectTo) {
            window.location.href = redirectTo;
            return;
        }
        if (import.meta.env.DEV) {
            window.location.href = "/settings";
        } else {
            window.location.href =
                getCurrentDomain() === "ignite"
                    ? import.meta.env.VITE_IGNITE_APP_URL
                    : import.meta.env.VITE_IGNITE_PROCUREMENT_APP_URL;
        }
    }

    function expandPasswordInput() {
        setShowPasswordInput(true);
        if (onShowPassword) {
            onShowPassword();
        }
        const currentTarget = passwordRef.current;
        if (currentTarget) {
            setTimeout(() => currentTarget.focus(), 200);
        }
    }

    useEffect(() => {
        if (requireMfa) {
            setTimeout(() => mfaRef.current?.focus(), 200);
        }
    }, [requireMfa]);

    async function onSubmitEmail(email: string) {
        setLoading(true);
        setEmail(email);
        localStorage.setItem("lastEmail", email);
        const { oidc, error } = await getAppropriateLoginMethod(email);

        if (error) {
            // user_not_found -> we used to log, but we currently don't handle it
            if (error.code == "sso_required") {
                const url = new URLSearchParams({ email }).toString();
                window.location.href = `/link-required?${url}`;
                return;
            }
            setError(humanReadableError(error));
            setLoading(false);
            return;
        }

        if (oidc) {
            const provider = getProvider(oidc.provider);
            if (!provider) {
                setError(formatMessage(messages.unknownProvider));
                setLoading(false);
                return;
            }

            // TODO: Needs to be enforced backend as well
            setSsoProvider(provider.id);
            ssoLogin(provider, email, oidc.domain, { refresh, returnTo: redirectTo });
        } else {
            // default to password login
            setLoading(false);
            expandPasswordInput();
        }
    }

    async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (requireMfa) {
            // user is submitting mfa code
            return onMFASubmit(e);
        }
        const fd = new FormData(e.currentTarget);
        const email = fd.get("email") as string | null;
        const password = fd.get("password") as string | null;
        if (!email) {
            // should never happen
            return;
        }

        if (!showPasswordInput) {
            return onSubmitEmail(email);
        }
        // ensure password and email are not null before proceeding
        if (password == null || email == null) {
            Sentry.captureMessage(`login methods is null - should never happen`);
            setError(formatMessage(messages.genericError));
            return;
        }
        setLoading(true);
        const { data, error } = await passwordLogin(email, password, { refresh });
        if (error) {
            const { code } = error;
            switch (code) {
                case "session_already_available":
                    // special case: user is logged in, but for some reason not redirected. Instead of complaining,
                    // we'll just pretend it's ok
                    Sentry.captureMessage("session_already_available, redirect to app", {
                        user: { email },
                        tags: {
                            method: "password",
                            app: "auth-frontend",
                            emailDomain: email.split("@")[1],
                        },
                        level: "info",
                    });
                    setLoading(false);
                    redirectToApp();
                    return;
                case "not_verified":
                    break;
                case "aal2_required":
                    setRequireMfa(true);
                    setLoading(false);
                    return;
                case "no_workspaces_found":
                    Sentry.captureMessage("user logged in, but no workspaces found", {
                        user: { email },
                        tags: {
                            method: "password",
                            app: "auth-frontend",
                        },
                        level: "info",
                    });
                    window.location.href = `/no-workspaces?email=${email}`;
                    return;
            }
            setError(humanReadableError(error));
            track("login failed", { method: "password", email, code, details: error.details });
            Sentry.captureMessage("login failed", {
                tags: {
                    method: "password",
                    app: "auth-frontend",
                    emailDomain: email.split("@")[1],
                    code: error.code,
                    details: error.details,
                },
            });
            setLoading(false);
            return;
        }
        onLoginSuccess(data);
        setLoading(false);
    }

    async function onMFASubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const fd = new FormData(e.currentTarget);
        const code = fd.get("code") as string;
        setLoading(true);
        const { data, error } = await totpLogin(code);
        if (error) {
            setError(humanReadableError(error));
            setLoading(false);
            return;
        }
        onLoginSuccess(data);
        setLoading(false);
    }

    return (
        <Card sx={{ py: 4, px: 3, boxShadow: 4, width: "444px" }}>
            <Stack justifyItems="start" alignItems="center" gap={4.5}>
                <IgniteIcon width="32px" />

                {/* Header */}
                <Stack gap={1}>
                    <Typography textAlign="center" variant="h6" color="textPrimary">
                        {title}
                    </Typography>
                    {subtitle && (
                        <Typography textAlign="center" variant="textSm" fontWeight="400" color="textSecondary">
                            {subtitle}
                        </Typography>
                    )}
                </Stack>

                {/* inputs */}
                <Stack
                    direction="column"
                    id="inputs"
                    component="form"
                    width="100%"
                    onSubmit={onSubmit}
                    justifyContent="flex-end"
                    gap={2}
                >
                    <TextField
                        inputRef={usernameRef}
                        sx={{ minWidth: "350px", display: requireMfa && !showPasswordInput ? "none" : "flex" }}
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        autoComplete="username"
                        placeholder={formatMessage({ defaultMessage: "Enter your email" })}
                        label={formatMessage(messages.email)}
                        variant="outlined"
                    />
                    <Collapse in={showPasswordInput}>
                        <TextField
                            fullWidth
                            placeholder={formatMessage({ defaultMessage: "Enter your password" })}
                            inputRef={passwordRef}
                            autoComplete="current-password"
                            name="password"
                            label={formatMessage(messages.labelPassword)}
                            type="password"
                            variant="outlined"
                        />
                    </Collapse>

                    <Collapse in={requireMfa} unmountOnExit sx={{ pt: 1, pb: 3 }}>
                        <TextField
                            fullWidth
                            placeholder={formatMessage({ defaultMessage: "Enter your MFA code" })}
                            inputProps={{ minLength: 6, maxLength: 6 }}
                            inputRef={mfaRef}
                            sx={{ minWidth: "350px" }}
                            name="code"
                            label={formatMessage(messages.labelMFA)}
                            variant="outlined"
                        />
                    </Collapse>
                    <button type="submit" style={{ display: "none" }} />
                </Stack>

                {/* frame2 */}
                <Stack width="100%" alignItems="center" gap={3}>
                    <LoadingButton
                        type="submit"
                        loading={loading}
                        form="inputs"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="medium"
                    >
                        {/* wrap in a span to prevent errors if a user translates the page. See https://github.com/facebook/react/issues/11538#issuecomment-359558410 */}
                        <Typography variant="body1">
                            {formatMessage(showPasswordInput ? messages.signIn : messages.continue)}
                        </Typography>
                    </LoadingButton>

                    <Collapse in={showPasswordInput}>
                        <Link
                            component={RouterLink}
                            variant="subtitle2"
                            to="/recovery"
                            onClick={() => {
                                sessionStorage.setItem("email", email);
                            }}
                            sx={{
                                alignSelf: "center",
                            }}
                        >
                            {formatMessage(messages.forgotPassword)}
                        </Link>
                    </Collapse>
                </Stack>
                {error && (
                    <Stack width="100%">
                        <Alert severity="error">{error}</Alert>
                    </Stack>
                )}
            </Stack>
        </Card>
    );
};

export default LoginForm;
