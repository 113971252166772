import { Alert, CircularProgress, Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

import LoginForm from "@/components/LoginForm";
import { useSession } from "@/hooks/useSession";
import { getCurrentDomain } from "@/utils/getCurrentHostname";
import messages from "./messages";

function getRedirectUrl(redirectUrl: string | null) {
    if (redirectUrl == null) {
        return undefined;
    }
    if (redirectUrl.startsWith("/")) {
        return redirectUrl;
    }
    try {
        const parsedUrl = new URL(redirectUrl);
        if (
            parsedUrl.origin != import.meta.env.VITE_IGNITE_APP_URL &&
            parsedUrl.origin != import.meta.env.VITE_IGNITE_PROCUREMENT_APP_URL
        ) {
            // eslint-disable-next-line no-console
            console.warn(
                `Provided url ${redirectUrl} with origin ${parsedUrl.origin} can not be used as the origin does not match the configured origins {${import.meta.env.VITE_IGNITE_APP_URL},${import.meta.env.VITE_IGNITE_PROCUREMENT_APP_URL}}`
            );
            Sentry.captureMessage("invalid redirect_url query param: not on ignite app domain: " + redirectUrl);
            return undefined;
        }
        return redirectUrl;
    } catch {
        return undefined;
    }
}

function LoginPage() {
    const [query] = useSearchParams();
    const { formatMessage } = useIntl();

    useEffect(() => {
        const id = query.get("error") as keyof typeof messages | null;
        if (id == null) {
            return;
        }
        const m = messages[id];
        if (m == null) {
            Sentry.captureMessage(`user entered login page with unknown error id: ${id}`);
            return;
        }
    }, [query, formatMessage]);

    const searchParams = new URLSearchParams(window.location.search);
    const redirectTo = getRedirectUrl(searchParams.get("redirect_url"));

    const sessionExpired = useMemo(() => {
        return query.get("sessionExpired") == "true";
    }, [query]);

    const sess = useSession();
    const shouldRedirect = useMemo(() => {
        return sess.data && !sessionExpired;
    }, [sess, sessionExpired]);
    if (shouldRedirect) {
        const igniteAppUrl =
            getCurrentDomain() === "ignite"
                ? import.meta.env.VITE_IGNITE_APP_URL
                : import.meta.env.VITE_IGNITE_PROCUREMENT_APP_URL;
        window.location.href = redirectTo || igniteAppUrl;
        return <CircularProgress />;
    }

    return (
        <Stack alignItems="center" gap={4}>
            {sessionExpired && (
                <Stack width="100%">
                    <Alert severity="warning">{formatMessage(messages.sessionExpiredAlert)}</Alert>
                </Stack>
            )}
            <LoginForm
                redirectTo={redirectTo}
                title={formatMessage(messages.welcomeBack)}
                subtitle={formatMessage(messages.loginTitle)}
            />
        </Stack>
    );
}

export default LoginPage;
