import { useState } from "react";
import { identify, track } from "@ignite-analytics/track";
import { Navigate, Link as RouterLink } from "react-router-dom";
import { ArrowLeft } from "@ignite-analytics/icons";
import Logo from "@/assets/main-logo.svg";

import { initRecoveryFlowV2, RecoveryMethod } from "@/hooks/useRecoveryFlow";
import { Button, Card, Link, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/system";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

export const RecoveryPage = () => {
    const { formatMessage } = useIntl();
    const [email, setEmail] = useState(sessionStorage.getItem("email") ?? "");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const method: RecoveryMethod = "code";

    const [showConfirmation, setShowConfirmation] = useState(false);

    async function onSubmitEmail(e: React.FormEvent<HTMLFormElement>) {
        identify({ email, type: "anonymous" });
        track("account recovery: initiated", { method });
        e.preventDefault();
        setLoading(true);
        try {
            const res = await initRecoveryFlowV2(email, method);
            if (res.error) {
                return;
            }
            // Honestly not sure this is the best way to do it.
            // Definitely don't want to pass it as query params, as that might be leaked in logs:
            // https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html#transmissing-csrf-tokens-in-synchronized-patterns
            if (method == "code") {
                sessionStorage.setItem("csrf-token", res.data.csrf);
                sessionStorage.setItem("email", email);
                navigate(`/recovery/submit?flow=${res.data.flowId}`);
            } else {
                // this is the 'legacy' link flow
                setShowConfirmation(true);
            }
        } finally {
            setLoading(false);
        }
    }

    if (showConfirmation) {
        return <Navigate to="/recovery-sent" />;
    }

    // design file: https://www.figma.com/design/aFamF21J5oQRAGmOF83WMr/Log-in-%2F-Account-creation?node-id=6-113&node-type=canvas&m=dev
    return (
        <Card sx={{ py: 4, px: 3, width: "444px" }}>
            <Stack gap={4.5} alignItems="center" width="100%">
                <Stack sx={{ cursor: "pointer", marginLeft: "2px" }} py={0.5} alignItems="center">
                    <Link>
                        <img width={27} alt="Ignite Procurement Logo" src={Logo} />
                    </Link>
                </Stack>

                <Stack
                    width="100%"
                    gap={4.5}
                    alignItems="center"
                    id="password-form"
                    component="form"
                    onSubmit={onSubmitEmail}
                >
                    <Stack gap={1} alignItems="center">
                        <Typography variant="textXl" fontWeight="500">
                            <FormattedMessage defaultMessage="Verify your identity" />
                        </Typography>
                        <Typography variant="textSm" color="textSecondary">
                            <FormattedMessage defaultMessage="Enter your email address to receive a one-time code." />
                        </Typography>
                    </Stack>

                    <TextField
                        name="email"
                        size="medium"
                        autoFocus
                        fullWidth
                        autoComplete="username"
                        value={email}
                        label={formatMessage({ defaultMessage: "Email" })}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={formatMessage({ defaultMessage: "Enter your email" })}
                        variant="outlined"
                    />
                    <input type="submit" style={{ display: "none" }} />
                </Stack>
                <Stack gap={3.5} alignItems="center" width="100%">
                    <LoadingButton
                        fullWidth
                        type="submit"
                        form="password-form"
                        variant="outlined"
                        color="primary"
                        loading={loading}
                    >
                        <FormattedMessage defaultMessage="Send email" />
                    </LoadingButton>
                    {/* back to login */}
                    <Button component={RouterLink} to="/login" startIcon={<ArrowLeft />} size="small" color="ghostGray">
                        <FormattedMessage defaultMessage="Back to login" />
                    </Button>
                </Stack>
            </Stack>
        </Card>
    );
};
