import { LoadingButton } from "@mui/lab";
import { Card, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useRecoveryFlow } from "@/hooks/useRecoveryFlow";

import { useSession } from "@/hooks/useSession";
import { getCurrentDomain } from "@/utils/getCurrentHostname";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IgniteIcon } from "@/components/LoginForm/Icon";
import { identify, track } from "@ignite-analytics/track";

const LinkRequiredPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const recovery = useRecoveryFlow();
    const [searchParams] = useSearchParams();
    const initialEmail = searchParams.get("email");
    const [email, setEmail] = React.useState(initialEmail ?? "");

    const [sentAt, setSentAt] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        setLoading(true);
        e.preventDefault();

        const timeSinceLastSent = Date.now() - sentAt;
        if (timeSinceLastSent < 1000 * 60 * 1) {
            return;
        }
        const formData = new FormData(e.currentTarget);
        const email = formData.get("email") as string;
        localStorage.setItem("sso-link-init", "1");
        if (!email) {
            return;
        }
        await recovery
            .send(email)
            .then(() => setSentAt(Date.now()))
            .catch((e) => {
                Sentry.captureException(`failed to send recovery email: ${JSON.stringify(e)}`);
            });
        identify({ email, type: "anonymous" });
        track("Link account: Sent recovery e-mail");
        setLoading(false);
    }

    const sess = useSession();
    if (sess.data) {
        window.location.href =
            getCurrentDomain() === "ignite"
                ? import.meta.env.VITE_IGNITE_APP_URL
                : import.meta.env.VITE_IGNITE_PROCUREMENT_APP_URL;
        return <CircularProgress />;
    }

    return (
        <Card sx={{ py: 4, px: 3, boxShadow: 4, width: "444px" }}>
            <Stack
                justifyItems="start"
                alignItems="center"
                gap={4.5}
                id="inputs"
                component="form"
                onSubmit={async (e) => {
                    await handleSubmit(e);
                    navigate("/recovery-sent"); // guess this suffice for now
                }}
            >
                <IgniteIcon width="32px" />

                {/* Header */}
                <Stack gap={1}>
                    <Typography textAlign="center" variant="h6" color="textPrimary">
                        <FormattedMessage defaultMessage="Your account is not linked to your  Microsoft Account" />
                    </Typography>
                    <Typography textAlign="center" variant="textSm" fontWeight="400" color="textSecondary">
                        <FormattedMessage defaultMessage="To use Social Sign In, you must link your account to Mircosoft. Receive a one-time link by email, and then link your account at the settings page." />
                    </Typography>
                </Stack>

                {/* inputs */}
                <Stack direction="column" width="100%" justifyContent="flex-end" gap={2}>
                    <TextField
                        sx={{ minWidth: "350px" }}
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        autoComplete="username"
                        placeholder={formatMessage({ defaultMessage: "Enter your email" })}
                        label={formatMessage({ defaultMessage: "Email" })}
                        variant="outlined"
                    />
                </Stack>

                {/* frame2 */}
                <Stack width="100%" alignItems="center" gap={3}>
                    <LoadingButton
                        loading={loading}
                        type="submit"
                        form="inputs"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="medium"
                    >
                        <Typography variant="body1">
                            <FormattedMessage defaultMessage="Continue" />
                        </Typography>
                    </LoadingButton>
                </Stack>
            </Stack>
        </Card>
    );
};

export default LinkRequiredPage;
